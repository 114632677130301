<template>
  <v-card style="max-width: 500px">
    <v-card-text>
      <v-row>
        <v-col sm="2" class="d-flex align-center justify-center">
          <v-icon>{{ fileIcon }}</v-icon>
        </v-col>
        <v-col>
          <div class="d-flex flex-column">
            <span class="text--primary">{{ file.filename }}</span>
            <div class="text--secondary">
              <span>{{ sizeInKBytes }}kb</span>
              <template v-if="isUpload">
                <span class="mx-2">•</span>
                <span>Complete</span>
              </template>
            </div>
          </div>
        </v-col>
        <v-col sm="1" class="d-flex align-center justify-end">
          <v-btn rounded icon @click="onAction">
            <v-icon>{{ actionIcon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: "upload",
      validator: (value) => ["upload", "download"].includes(value),
    },
  },

  computed: {
    isUpload() {
      return this.variant === "upload";
    },
    fileIcon() {
      return this.isUpload ? "mdi-file-upload-outline" : "mdi-file-document";
    },
    actionIcon() {
      return this.isUpload ? "mdi-close" : "mdi-download";
    },
    sizeInKBytes() {
      return Math.round(this.file.size / 1024);
    },
  },

  methods: {
    onAction() {
      if (this.isUpload) {
        return this.$emit("close");
      }
      this.download(this.file.url, this.file.filename);
    },
    download(uri, name) {
      const link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
